// import appConfig from "configs/app.config";
// import ApiService from "./ApiService";

// const BASE_URL = `${appConfig.servicePrefix[appConfig.stage].auth}`;

// export async function apiSignIn(data) {
//   return ApiService.fetchData({
//     url: `${
//       appConfig.servicePrefix[appConfig.stage].auth
//     }/user/login/dashboard`,
//     method: "post",
//     data,
//   });
// }

// export async function apiSignUp(data) {
//   return ApiService.fetchData({
//     url: "/sign-up",
//     method: "post",
//     data,
//   });
// }

// export async function apiSignOut(data) {
//   return ApiService.fetchData({
//     url: "/sign-out",
//     method: "post",
//     data,
//   });
// }

// export async function apiForgotPassword(data) {
//   return ApiService.fetchData({
//     url: "/forgot-password",
//     method: "post",
//     data,
//   });
// }

// export async function apiResetPassword(data) {
//   return ApiService.fetchData({
//     url: "/reset-password",
//     method: "post",
//     data,
//   });
// }

// export async function apiGetUser(params) {
//   return ApiService.fetchData({
//     url: `${appConfig.servicePrefix[appConfig.stage].auth}/user`,
//     method: "get",
//     params,
//   });
// }

// export async function apiCreateUser(data) {
//   return ApiService.fetchData({
//     url: `${appConfig.servicePrefix[appConfig.stage].auth}/user`,
//     method: "post",
//     data,
//   });
// }

// export async function apiUpdateUser(data) {
//   return ApiService.fetchData({
//     url: `${appConfig.servicePrefix[appConfig.stage].auth}/user`,
//     method: "put",
//     data,
//   });
// }

// export async function apiDeleteUser(data) {
//   return ApiService.fetchData({
//     url: `${appConfig.servicePrefix[appConfig.stage].auth}/user`,
//     method: "delete",
//     data,
//   });
// }

// export async function apiCreateUserAuthority(data) {
//   return ApiService.fetchData({
//     url: `${BASE_URL}/user/authority`,
//     method: "post",
//     data,
//   });
// }

// export async function apiExportXlsxUser(params) {
//   return ApiService.fetchData({
//     url: `${BASE_URL}/user/xlsx`,
//     method: "get",
//     responseType: "blob",
//     params,
//   });
// }

// export async function apiGetAuthority(params) {
//   return ApiService.fetchData({
//     url: `${BASE_URL}/user/authority`,
//     method: "get",
//     params,
//   });
// }

// // Company
// const COMPANY = "company";
// export async function apiGetCompany(params) {
//   return ApiService.fetchData({
//     url: `${BASE_URL}/${COMPANY}`,
//     method: "get",
//     params,
//   });
// }

// export async function apiCreateCompany(data) {
//   return ApiService.fetchData({
//     url: `${BASE_URL}/${COMPANY}`,
//     method: "post",
//     data,
//   });
// }

// export async function apiUpdateCompany(data) {
//   return ApiService.fetchData({
//     url: `${BASE_URL}/${COMPANY}`,
//     method: "put",
//     data,
//   });
// }

// export async function apiDeleteCompany(data) {
//   return ApiService.fetchData({
//     url: `${BASE_URL}/${COMPANY}`,
//     method: "delete",
//     data,
//   });
// }

// const FILE = "file";
// export async function apiCreateFile(data) {
//   return ApiService.fetchData({
//     url: `${BASE_URL}/${FILE}`,
//     method: "post",
//     data,
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   });
// }

// export async function apiGetNotification(params) {
//   return ApiService.fetchData({
//     url: `${appConfig.servicePrefix[appConfig.stage].auth}/notification`,
//     method: "get",
//     params,
//   });
// }

import appConfig from "configs/app.config";
import ApiService from "./ApiService";

const BASE_URL = appConfig.servicePrefix?.auth || "";

/**
 * Helper function for making API requests
 * @param {string} endpoint - API endpoint
 * @param {string} method - HTTP method (GET, POST, PUT, DELETE)
 * @param {Object} [options={}] - Additional options (data, params, headers, responseType)
 * @returns {Promise}
 */
const apiRequest = (endpoint, method, options = {}) => {
  return ApiService.fetchData({
    url: `${BASE_URL}${endpoint}`,
    method,
    ...options,
  });
};

export const apiSignIn = (data) =>
  apiRequest("/user/login/dashboard", "post", { data });
export const apiSignUp = (data) => apiRequest("/sign-up", "post", { data });
export const apiSignOut = (data) => apiRequest("/sign-out", "post", { data });
export const apiForgotPassword = (data) =>
  apiRequest("/forgot-password", "post", { data });
export const apiResetPassword = (data) =>
  apiRequest("/reset-password", "post", { data });

export const apiGetUser = (params) => apiRequest("/user", "get", { params });
export const apiCreateUser = (data) => apiRequest("/user", "post", { data });
export const apiUpdateUser = (data) => apiRequest("/user", "put", { data });
export const apiDeleteUser = (data) => apiRequest("/user", "delete", { data });

export const apiCreateUserAuthority = (data) =>
  apiRequest("/user/authority", "post", { data });
export const apiGetAuthority = (params) =>
  apiRequest("/user/authority", "get", { params });

export const apiExportXlsxUser = (params) =>
  apiRequest("/user/xlsx", "get", { params, responseType: "blob" });

const COMPANY = "/company";
export const apiGetCompany = (params) => apiRequest(COMPANY, "get", { params });
export const apiCreateCompany = (data) => apiRequest(COMPANY, "post", { data });
export const apiUpdateCompany = (data) => apiRequest(COMPANY, "put", { data });
export const apiDeleteCompany = (data) =>
  apiRequest(COMPANY, "delete", { data });

export const apiCreateFile = (data) =>
  apiRequest("/file", "post", {
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });

export const apiGetNotification = (params) =>
  apiRequest("/notification", "get", { params });
