// const appConfig = {
//   servicePrefix: {
//     local: {
//       auth: "http://localhost:40002",
//       district: "https://api.lebakkinasih.com/district",
//       file: "https://api.lebakkinasih.com/file",
//       project: "http://localhost:40003",
//       technique: "http://localhost:40003",
//       legal: "http://localhost:40004",
//       administration: "http://localhost:40003",
//       marketing: "http://localhost:40004",
//       hrga: "http://localhost:40005",
//       finance: "http://localhost:40005",
//       accounting: "http://localhost:40005",
//     },
//     production: {
//       auth: "https://api.lebakkinasih.com/auth",
//       district: "https://api.lebakkinasih.com/district",
//       file: "https://api.lebakkinasih.com/file",
//       project: "https://api.lebakkinasih.com/project",
//       technique: "https://api.lebakkinasih.com/project",
//       administration: "https://api.lebakkinasih.com/project",
//       legal: "https://api.lebakkinasih.com/marketing",
//       marketing: "https://api.lebakkinasih.com/marketing",
//       hrga: "https://api.lebakkinasih.com/finance",
//       finance: "https://api.lebakkinasih.com/finance",
//       accounting: "https://api.lebakkinasih.com/finance",
//     },
//   },
//   authenticatedEntryPath: "/home",
//   unAuthenticatedEntryPath: "/sign-in",
//   tourPath: "/",
//   locale: "en",
//   enableMock: false,
//   stage: "local",
// };

// export default appConfig;

const ENVIRONMENTS = {
  PRODUCTION: "production",
  LOCAL: "local",
};

const servicePrefix = {
  [ENVIRONMENTS.PRODUCTION]: {
    auth: "https://api.lebakkinasih.com/auth",
    district: "https://api.lebakkinasih.com/district",
    file: "https://api.lebakkinasih.com/file",
    project: "https://api.lebakkinasih.com/project",
    technique: "https://api.lebakkinasih.com/project",
    administration: "https://api.lebakkinasih.com/project",
    legal: "https://api.lebakkinasih.com/marketing",
    marketing: "https://api.lebakkinasih.com/marketing",
    hrga: "https://api.lebakkinasih.com/finance",
    finance: "https://api.lebakkinasih.com/finance",
    accounting: "https://api.lebakkinasih.com/finance",
  },
  [ENVIRONMENTS.LOCAL]: {
    auth: "http://localhost:40002",
    district: "https://api.lebakkinasih.com/district",
    file: "https://api.lebakkinasih.com/file",
    project: "http://localhost:40003",
    technique: "http://localhost:40003",
    legal: "http://localhost:40004",
    administration: "http://localhost:40003",
    marketing: "http://localhost:40004",
    hrga: "http://localhost:40005",
    finance: "http://localhost:40005",
    accounting: "http://localhost:40005",
  },
};

const stage = ENVIRONMENTS.PRODUCTION;

const appConfig = {
  servicePrefix: servicePrefix[stage] || servicePrefix[ENVIRONMENTS.LOCAL],
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
  stage: stage,
};

export default appConfig;
