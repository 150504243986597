import appConfig from "configs/app.config";
import ApiService from "services/ApiService";

export const Body = {
  JSON: "application/json",
  FORM: "multipart/form-data",
};

export default class Index {
  static baseURL = `${appConfig.servicePrefix.auth}/user`;

  /**
   * 🔹 Request utama untuk API
   * @param {string} method - Metode HTTP (GET, POST, PUT, DELETE).
   * @param {Object} options - Opsi request.
   * @param {string} [options.endpoint=""] - Endpoint tambahan.
   * @param {Object} [options.data=null] - Body data.
   * @param {Object} [options.params=null] - Query params.
   * @param {Object} [options.headers={}] - Header tambahan.
   * @param {string} [options.contentType=Body.JSON] - Content-Type request.
   * @param {string} [responseType="json"] - Tipe response.
   * @returns {Promise<Object>} - Hasil response dari API.
   */
  static async request(
    method,
    {
      endpoint = "",
      data = null,
      params = null,
      headers = {},
      contentType = Body.JSON,
    } = {},
    responseType = "json"
  ) {
    try {
      return await ApiService.fetchData({
        url: `${this.baseURL}${endpoint}`,
        method,
        data,
        params,
        headers: {
          "Content-Type": contentType,
          ...headers,
        },
        responseType,
      });
    } catch (error) {
      console.error(
        `API Error [${method.toUpperCase()}] ${this.baseURL}${endpoint}:`,
        error.response?.data || error.message
      );
      throw error;
    }
  }

  static read(params, responseType = "json") {
    return this.request("get", { params }, responseType);
  }

  static create(data, contentType = Body.JSON) {
    return this.request("post", { data, contentType });
  }

  static update(data, contentType = Body.JSON) {
    return this.request("put", { data, contentType });
  }

  static delete(data, contentType = Body.JSON) {
    return this.request("delete", { data, contentType });
  }

  static login(data) {
    return this.request("post", { endpoint: "/login", data });
  }

  static register(data) {
    return this.request("post", { endpoint: "/register", data });
  }
}
